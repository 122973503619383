<template>
  <div class="wrapper">
    <v-app-bar color="primary" class="flex-grow-0">
      <v-btn fab color="white" width="40" height="40" to="/admin/">
        <img src="@/assets/SplashBox.png" height="30" />
      </v-btn>
      <h3 class="font-weight-medium ml-4">ILP Creator Mode</h3>
      <v-spacer />
      <v-btn v-if="$vuetify.breakpoint.mdAndUp" text color="white" href="/"> Exit Admin </v-btn>
      <v-btn v-else text color="white" icon href="/">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="flex-grow-1">
      <router-view></router-view>
    </v-main>
    <v-divider />
    <v-footer color="white" padless class="footer justify-center">
      <img src="@/assets/MCAST.png" height="20" />
    </v-footer>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.footer {
  height: 52px;
  // border-top: 1px solid black;
}
</style>
